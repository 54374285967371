.uploaderContainer  {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}


.uploaderContainer [type=file] {
   position: absolute;
   opacity: 0;
   width: 0px;
   height: 0px;
   pointer-events: none;
}
