.container {
    position: absolute;
    width: 100%;
    height:  100%;
    // border: 1px solid rgba(255, 0, 0, 0.75);

}

// .croppedImage {
//     height: 100%;

// }

.userIcon {
    // width: 100%;
    height: 40%;
    // border: 1px solid rgb(60,60,60)
}

// .cropContainer {
//     // position: absolute;
//     // top: 0;
//     // left: 0;
//     // right: 0;
//     // bottom: 80px;
//     // border: 1px solid rgba(255, 0, 0, 0.5);
// }

.controls {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;

}

.slider {

}

.logoOverlay {
    position: absolute;
    bottom: 4.5%;
    right: 3.5%;
    width: 40%;
  }