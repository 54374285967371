$width: 90vw;
$large-screen-width: 40vw;

$spacing: 6px;
$border: 1px solid rgb(60, 60, 60);
$font-family: 'Avenir',
-apple-system,
BlinkMacSystemFont,
"Segoe UI",
"Roboto",
"Oxygen",
"Ubuntu",
"Cantarell",
"Fira Sans",
"Droid Sans",
"Helvetica Neue",
sans-serif;
$font-size: 1rem;

.layout {
  min-height: 100vh;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photoContainer {
  position: relative;
  width: $width;
  height: $width;
  margin-bottom: $spacing;
  display: flex;
  justify-content: center;
  border: $border;
}

.button {
  border: $border;
  width: $width;
  margin-bottom: $spacing;
  font-family: $font-family;
  font-size: $font-size;
  color: lighten(#262626, 30%);
  background-color: transparent;
  padding: 0.2rem;
}

.button:hover {
  color: #C4A155;
  border: 1px solid #C4A155;
}

.button:focus {
  outline: none;
}

.createButton {
  color: #C4A155;
  border: 1px solid #C4A155;
  ;
}

.createButton:disabled {
  opacity: 0.4;
  color: lighten(#262626, 30%);
  border: $border;
}


.captionInputContainer {
  border: $border;
  width: $width;
  margin-bottom: $spacing;
}

.captionInput {
  font-family: $font-family;
  font-size: $font-size;
  height: 100%;
  width: -webkit-fill-available;
  background-color: transparent;
  color: #C4A155;
  min-height: 10vh;
  // border: 1vmin solid #262626;
  padding: 0.4rem;
  resize: none;
  border: none;
}

.captionInput::placeholder {
  color: lighten(#262626, 30%);
}

.captionInput:focus {
  outline: none;
}

.pricetagWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing;
}

.pricetagContainer {
  border: $border;
  width: $width;
  display: flex;
  align-items: center;

  span {
    font-family: $font-family;
    font-size: $font-size;
    color: lighten(#262626, 30%);
    margin-right: 0.5rem;
  }
}

.pricetagContainerActive {
  span {
    color: #C4A155;
  }
}

.priceInput {
  padding: 0.4rem;
  text-align: right;
  font-family: $font-family;
  font-size: $font-size;
  background-color: transparent;
  color: #C4A155; // lighten(#262626, 60%);
  border: none;
  flex-grow: 1;

}

.priceInput::placeholder {
  color: lighten(#262626, 30%);
  text-align: initial;
}

.priceInput:focus {
  outline: none;
}

.minimumPrice {
  font-family: $font-family;
  font-size: 0.8rem;
  color: #C4A155;
  text-align: right;
  width: 100%;
}

.terms {
  width: $width;
  margin-bottom: $spacing;
  display: flex;
  align-items: center;
}

.termsCaption {
  font-family: $font-family;
  font-size: $font-size;
  color: lighten(#262626, 30%);
}

@media (min-width: 736px) {
  .photoContainer {
    width: $large-screen-width;
    height: $large-screen-width;
  }

  .button {
    width: $large-screen-width;
  }

  .captionInputContainer {
    width: $large-screen-width;
  }

  .pricetagContainer {
    width: $large-screen-width;
  }

  .terms {
    width: $large-screen-width;
  }
}


.styledCheckbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  &+label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  &+label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: none;
    border: $border;
  }

  // Box hover
  &:hover+label:before {
    // background: #f35429;
    background: none;
    border: 1px solid #C4A155;
  }

  // Box focus
  &:focus+label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked+label:before {
    // background: #f35429;
  }

  // Disabled state label.
  &:disabled+label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled+label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked+label:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 10px;
    background: #C4A155;
    width: 2px;
    height: 2px;
    box-shadow:
      2px 0 0 #C4A155,
      4px 0 0 #C4A155,
      4px -2px 0 #C4A155,
      4px -4px 0 #C4A155,
      4px -6px 0 #C4A155,
      4px -8px 0 #C4A155;
    transform: rotate(45deg);
  }
}

.loader {
  position: fixed;
  top:0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #262626e0;
  overflow: hidden;
}