.App {
  text-align: center;
  color: white;
  height: 200vh;
}

// .goldBorder {
//   z-index: 2;
//   position: fixed;
//   height: 100vh;
//   width: 100vw;
//   transition: outline-color 250ms ease-in-out;
//   outline-width: 1px;
//   outline-style: solid;
//   outline-color: rgba(#C4A155, .5);
//   outline-offset: -3vmin;
// }

// .goldBorder.entered {
//   outline-color: rgba(#C4A155, 0);
// }

// .instagramLogo {
//   z-index: 3;
//   transition: opacity 250ms ease-in-out;
//   position: fixed;
//   top: 60vh;
//   left: calc(50vw - 5vmin);
//   opacity: 1;
//   width: 10vmin;
//   cursor: pointer;
// }

// .instagramLogo.entered {
//   opacity: 0;
//   z-index: -1;
// }

// .mainPage {
//   min-height: 100vh;
// }

// .header {
//   z-index: 1;
//   width: 100%;
//   position: fixed;
//   top: 0;
//   height: 13vmin;
//   display: block;
// }

// .header.entered {
//   background-color: black;
// }

// .logo {
//   transition: transform 250ms ease-in-out;
//   width: 20vmin;
//   margin-top: 2vmin;
//   margin-bottom: 6vmin;
//   transform: scale(3.0, 3.0) translate(0px, 12vh);
//   pointer-events: none;
// }

// .logo.entered {
//   transform: none;
// }

// .galleryContainer {
//   -webkit-box-flex: 1;
//   -webkit-flex-grow: 1;
//   -ms-flex-positive: 1;
//   flex-grow: 1;
//   margin: 0px auto 1000px;
//   padding-top: 13vmin;
//   max-width: 935px;
//   width: 100%;
// }

// @media (min-width: 736px) {
//   .galleryContainer {
//     -webkit-box-sizing: content-box;
//     box-sizing: content-box;
//     padding: 13vmin 20px 0;
//     width: calc(100% - 40px);
//   }
// }

.enter {
  opacity: 0;
}

.enterActive {
  transition: opacity 1000ms;
  opacity: 1;
}