body {
  background-color: black;
  color: white;
  user-select: none;
  font-family: 'Avenir',
-apple-system,
BlinkMacSystemFont,
"Segoe UI",
"Roboto",
"Oxygen",
"Ubuntu",
"Cantarell",
"Fira Sans",
"Droid Sans",
"Helvetica Neue",
sans-serif;
  /* -webkit-overflow-scrolling: touch; */
  /* -webkit-overflow-scrolling: auto; */
  /* overflow: hidden; */
}

a,
a:visited,
a:hover,
a:active,
a:focus,
a:active:hover {
  font-style: inherit;
  color: inherit;
  background-color: transparent;
  font-size: inherit;
  text-decoration: none;
  font-variant: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-family: inherit;
  border-radius: inherit;
  border: inherit;
  outline: inherit;
  box-shadow: inherit;
  padding: inherit;
  vertical-align: inherit;
}

h2 {
  text-transform: uppercase;
  background: linear-gradient(-30deg, #b9964e, #c5a256, #ead599, #faeab5, #c5a256, #a1783e);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.17em;
  margin-bottom: 5vh;
  font-family: 'Avenir', 'sans';
  font-size: 1em;
  
}

