.azertyu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}

.unlink {
  color: transparent !important;
  text-decoration: none;
}

.link {
  margin-bottom: 4vmin;
}

.instagramLogo {
  width: 12vmin;
  cursor: pointer;
}

.credits {
  // position: relative;
  // bottom: 10px;
  margin: auto;
  text-align: center;
  margin-bottom: 10px;
  font-size: 0.8rem;
  color: rgb(200, 200, 200);
}

.featuredOn {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px 50px 0px;
  opacity: .5;

}

.featured {
  height: 7vmin;
  margin: 2vmin 0px;
}