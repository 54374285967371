.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.container {
//  box-sizing: border-box;
//  max-width: 93.5rem;
//  margin: 0 auto;
//  padding: 0 2rem;

  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0px auto 0px;
  // padding-top: 13vmin;
  max-width: 900px;// 935px;
  width: 100%;
}

@media (min-width: 736px) {
  .container {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    // padding: 13vmin 20px 0;
    padding: 0 20px 0;
    width: calc(100% - 80px);
  }
}

.box {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.box > * {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 0%;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
}

.gallery {
//  border: 1px solid red;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
  padding-top: 0px;
}

.row {
 display: flex;
 flex-direction: row;
 -webkit-box-orient: horizontal;
 -webkit-box-direction: normal;
//  margin-bottom: 3px;
//  transition: margin-bottom 200ms;
}

// .row:last-child {
//   // margin-bottom: 3px;
// }
 
.thumbnailContainer {
  // background-color: #efefef;
  position: relative;
  display: block;
  width: 100%;
  // position: relative;

  margin-right: 3px;
  // transition: margin-right 200ms;
}

.thumbnailContainer:last-child {
  margin-right: 0px;
}


.thumbnailWrapper {
  transition: opacity 200ms ease-in;
}

.thumbnailImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}



@media (min-width: 736px) {
 .row {
  margin-bottom: 28px;
 }

 .row:last-child {
  margin-bottom: 0px;
 }

 .thumbnailContainer {
  margin-right: 28px;
 }

 .thumbnailContainer:last-child {
  margin-right: 0px;
 }
}

.logoOverlay {
  position: absolute;
  bottom: 4.5%;
  right: 3.5%;
  width: 40%;
}