$font-family: 'Avenir',
-apple-system,
BlinkMacSystemFont,
"Segoe UI",
"Roboto",
"Oxygen",
"Ubuntu",
"Cantarell",
"Fira Sans",
"Droid Sans",
"Helvetica Neue",
sans-serif;
$font-size: 1rem;
$gold: #C4A155;

.modal {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  left: 0;
  background-color: black;
}

@media (min-width: 736px) {
  .modal {
    background-color: rgba(19, 19, 19, 0.918);
    // display: flex;
    // justify-content: center;
  }
}

.layout {
  margin-top: 61px; // topbar
  width: 100vw;
  background-color: black;
  // border: 1px solid red;
}

.iconClose {
  display: none;
}

@media (min-width: 736px) {
  .layout {
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 600px;
    min-height: 800px;
    border-radius: 5px;

  }
  .iconClose {
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
    fill: rgb(255, 255, 255);
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
}





.photoContainer {
  position: relative;
  width: 100vw;
  height: 100vw;
  // background-color: rgb(78, 78, 78);
}

@media (min-width: 736px) {
  .photoContainer {
    width: 600px;
    height: 600px;
  }
}

.loader {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.photo {
  transition: opacity 200ms ease-in;
  width: 100%;
  // height: 100vw;
}

.logoOverlay {
  position: absolute;
  bottom: 4.5%;
  right: 3.5%;
  width: 40%;
}

.captionWrapper {
  padding-top: 2vmin;
  padding-left: 3vw;
  padding-right: 3vw;
}

.caption {
  font-family: $font-family;
  font-size: $font-size;
  color: $gold;
  user-select: all;
  // width: 100vw;
  // text-align: left;
}

.unlink {
  color: lighten($gold, 15%) !important;
  cursor: pointer;
}

.pricetagContainer {
  padding: 50px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.pricetagOutline {
  width: 50vw;
}

.amount {
  padding-left: 10vw;
  font-size: 7vw;
  letter-spacing: 1vw;

  align-self: center;
  position: absolute;

  font-weight: 500;
  background: linear-gradient(-30deg, #b9964e, #c5a256, #ead599, #faeab5, #c5a256, #a1783e);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  // margin-bottom: 5vh;
  font-family: 'Avenir', 'sans';
  // font-size: 1em;
}

@media (min-width: 736px) {
  .pricetagOutline {
    width: 300px;
  }
  .amount {
    padding-left: 60px;
    font-size: 42px;
    letter-spacing: 1px;


    // width: 600px;
    // height: 600px;
  }
}
