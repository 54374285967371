
.layout {
  width: 90vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: rgb(199, 199, 199);
  text-align: justify;
  margin: auto;
  // align-items: center;
  // justify-content: center;
}

.topBar {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 8vmin;
  min-height: 61px;
}

.logo {
  width: 100px;
  padding: 10px;
  justify-self: baseline;
}

.logo:hover {
  cursor: pointer;
}

.title {
  width: fit-content;
  text-transform: uppercase;
  background: linear-gradient(-30deg, #b9964e, #c5a256, #ead599, #faeab5, #c5a256, #a1783e);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.17em;
  margin-bottom: 3vmin;
  font-family: 'Avenir', 'sans';
  font-size: 2.2rem;
  margin-bottom: 8vmin;

}

.section {
  margin-bottom: 10vmin;
}

.section > .question {
  width: fit-content;
  text-transform: uppercase;
  background: linear-gradient(-30deg, #b9964e, #c5a256, #ead599, #faeab5, #c5a256, #a1783e);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.17em;
  margin-bottom: 3vmin;
  font-family: 'Avenir', 'sans';
  font-size: 1em;
  text-align: left;
}

@media (min-width: 736px) {
  .layout {
    margin: auto;
    width: 600px;

  }
}