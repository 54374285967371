$font-family: 'Avenir',
-apple-system,
BlinkMacSystemFont,
"Segoe UI",
"Roboto",
"Oxygen",
"Ubuntu",
"Cantarell",
"Fira Sans",
"Droid Sans",
"Helvetica Neue",
sans-serif;

.layout {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
}


.congrats {
  text-align: center;
  padding: 3vw;
  font-family: $font-family;
  font-size: 1.5rem;
  color: #C4A155;
  font-weight: 400;
  text-transform: uppercase;
  background: linear-gradient(-30deg, #b9964e, #c5a256, #ead599, #faeab5, #c5a256, #a1783e);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.17em;
}

.logo {
  width: 100px;
  padding: 10px;
  justify-self: baseline;
  min-height: 61px;
}

.logo:hover {
  cursor: pointer;
}

.azertyu{
  width: 100vw;
  height: 100vw;
  background-color: gray;
  justify-self: center;
}


@media (min-width: 736px) {
  .layout {
    margin: auto;
    width: 600px;

  }
  .azertyu {
    margin: auto;
    width: 500px;
    height: 500px;
  }
}

.photo {
  // background-color: gray;
  width: 100%;
}

.message {
  color: rgb(100, 100, 100);
  text-align: center;
  padding: 3vw;
  font-family: $font-family;
}