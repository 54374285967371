.loader {
  position: fixed;
  top:0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #262626e0;
  overflow: hidden;
}