.layout {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10;
  width: 100%;
  height: 60px;
  min-height: 60px;
  background-color: black;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  margin-bottom: 1px;
}

@media (min-width: 736px) {
  .layout {
    background-color: transparent;
    position: relative;
    display: none;
  }
}

.backWrapper {
  position: absolute;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.back {
  display: flex;
  align-items: center;
  color: rgb(60, 60, 60);
  fill: rgb(60, 60, 60);// #C4A155;
  text-decoration: none;
  margin-left: 1vw;
  font-size: 1.1rem;
}

@media (min-width: 736px) {
  .back {
    display: none;
  }
}

.back:hover {
  color: #C4A155;
  fill: #C4A155;
}

.back > svg {
  height: 25px;
}


.logo {
  padding: 10px;
}