.layout {
  // pointer-events: none;
  position: relative;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100%;
  width: 100vw;

  height: 100vh;
  /* Fallback for browsers that do not support Custom Properties */
  // height: calc(var(--vh, 1vh) * 100);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background-color: black;
  overflow: hidden;
  opacity: 0;
  transition: opacity 800ms ease-in;
}

.appear {
  opacity: 1 !important;
}

.goldBorder {
  position: absolute;
  // border: 1px solid red;
  height: 100%;
  width: 100vw;
  outline-width: 1px;
  outline-style: solid;
  outline-color: rgba(#C4A155, .5);
  outline-offset: -3vmin;
  transition: opacity 250ms;
}

.logo {
  // z-index: 2;
  width: 65vmin;
  margin-bottom: 6vmin;
  cursor: pointer;
  transition: transform 250ms;
  // pointer-events: none;
}


.instagramLogoLink {
  // z-index: 2;
  transition: opacity 250ms;
  margin-bottom: 14vmin;
}

.instagramLogo {
  width: 12vmin;
  cursor: pointer;
}

@media (min-width: 736px) {
  .logo {
    width: 50vmin;
    margin-bottom: 5vmin;
  }

  .instagramLogo {
    width: 10vmin;
  }

  .instagramLogoLink {
    margin-bottom: 0px;
  }

}