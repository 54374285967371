.enter {
  opacity: 0;
}

.enterActive {
  transition: opacity 1000ms;
  opacity: 1;
}

.exit  {
  opacity: 1;
  // transition: opacity 200ms, transform 200ms;

}

.exitActive {
  opacity: 0;
  transition: opacity 200ms, transform 200ms;
}

.section {
  height: 100vh;
}

.test {
  transition: width 0.3s ease-out;
  width: 100px;
  height: 100px;
  background-color: red;
  margin: 0 !important;
  
}

.yellow {
  background-color: yellow !important;
}
.zap {
  width: 100%;
}

.willChange {
  will-change: transform;
}